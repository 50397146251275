<template>
  <div class="container">
    <nav aria-label="breadcrumb " class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Home</li>
        <li class="breadcrumb-item">Evaluations</li>
        <li class="breadcrumb-item">
          {{ evaluationDetails.Name }}
        </li>
        <li class="breadcrumb-item"><a href="#" @click="$router.go(-1)">Members</a></li>

        <li class="breadcrumb-item" aria-current="page">Comparisation</li>
      </ol>
    </nav>

    <div class="row align-items-end">
      <div class="col">
        <h1 class="page-title">{{ evaluationDetails.Name }}</h1>
        <p>{{ evaluationDetails.Description }}</p>
      </div>
      <div class="col-auto" :style="{ width: scoreColumnWidth + 32 + 'px' }">
        <div class="bg-white rounded p-4">
          <label class="mb-4 fw-bold label">Evaluated For</label>
          <VueMultiselect v-model="evaluatedEmployee" track-by="EmployeeId" :allowEmpty="false" :options="evaluatedMembers" :placeholder="'Search for member'" label="Name" :searchable="true" :internalSearch="false" @select="SelectMember"></VueMultiselect>
        </div>
      </div>
      <div class="col-auto" :style="{ width: scoreColumnWidth + 32 + 'px' }">
        <div class="bg-white rounded p-4">
          <label class="mb-4 fw-bold label">Evaluated by</label>
          <VueMultiselect v-model="evaluatedBy" track-by="EmployeeId" :allowEmpty="false" :options="whoEvaluateMembers" :placeholder="'Search for member'" label="Name" :searchable="true" :internalSearch="false" @select="SelectEvaluateBy"></VueMultiselect>
        </div>
      </div>
    </div>

    <div class="card mb-8 mt-8 evaluation-summary-card">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col d-flex align-items-center">
            <img src="@/assets/images/networking.png" />
            <div>
              <small>Scope</small>
              <h5>{{ evaluationDetails.Scope }}</h5>
            </div>
          </div>
          <div class="col d-flex align-items-center">
            <img src="@/assets/images/networking.png" />
            <div>
              <small>Team</small>
              <h5>{{ evaluationDetails.Target }}</h5>
            </div>
          </div>
          <div class="col">
            <small>Target</small>
            <h5>{{ evaluationDetails.EvaluationFor }}</h5>
          </div>
          <div class="col">
            <small>Period</small>
            <h5>{{ $utils.formatDate(evaluationDetails.StartDate) }}- {{ $utils.formatDate(evaluationDetails.EndDate) }}</h5>
          </div>

          <div v-if="evaluated.IsSigned && whoEvaluate.IsSigned" class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
            <div class="big-score-bage bg-dark-green">

              <h1>{{ evaluated.EvaluationScore }}</h1>
              <span>{{ evaluatedEmployee?.Name }}</span>
            </div>
          </div>
          <div class="col-auto" v-if="whoEvaluate.IsSigned " :style="{ width: scoreColumnWidth + 'px' }">
            <div class="big-score-bage bg-orange">
              <h1>{{ whoEvaluate.EvaluationScore }}</h1>
              <span>{{ evaluatedBy?.Name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="evaluated.IsSigned && whoEvaluate.IsSigned" class="card">
      <div class="card-body">
        <div v-bind:key="categoryIndex" v-for="(category, categoryIndex) in answers">
          <div class="row">
            <div class="col">
              <h1>{{ category.CategoryName }}</h1>
              <p>
                {{ category.CategoryDescription }}
              </p>
            </div>

            <div v-if="evaluated.IsSigned == true" class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 text-gray-700">{{ evaluated.WhoEvaluatedName }} score</h5>
                </div>
                <div class="col-auto">
                  <span class="bg-gray-200 p-2 rounded text-dark-green fw-bold fs-18px ps-4 pe-4"><i class="fa-sharp fa-solid fa-star text-dark-green me-2"></i> {{ category.EvaluatedCategoryScore }}</span>
                </div>
              </div>
            </div>

            <div v-if="whoEvaluate.IsSigned == true" class="col-auto" :style="{ width: scoreColumnWidth + 'px' }">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="m-0 text-gray-700">{{ whoEvaluate.WhoEvaluatedName }} Score</h5>
                </div>
                <div class="col-auto">
                  <span class="bg-gray-200 p-2 rounded text-warning fw-bold fs-18px ps-4 pe-4"><i class="fa-sharp fa-solid fa-star text-warning me-2"></i> {{ category.CategoryScore }}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row align-items-center" v-bind:key="questionIndex" v-for="(question, questionIndex) in category.Questions">
            <div class="col">
              <question-timeline-details-component :question="question"></question-timeline-details-component>
            </div>
            <div v-if="evaluated.IsSigned == true" class="col-auto questions-score-div">
              <div class="question-score-list preview-list">
                <div class="question-score" v-bind:key="index" v-for="index in question.MaxScore" v-bind:class="{ active: index == question.EvaluatedGrade }">{{ index }}</div>
              </div>
            </div>

            <div v-if="whoEvaluate.IsSigned == true" class="col-auto">
              <div class="question-score-list preview-list">
                <div class="question-score" v-bind:key="index" v-for="index in question.MaxScore" v-bind:class="{ active: index == question.Grade }">{{ index }}</div>
              </div>
            </div>
          </div>

          <hr class="mt-15 mb-15" />
        </div>
      </div>

      <div class="card-px text-center pt-15 pb-15" v-if="evaluated.IsSigned != true && whoEvaluate.IsSigned != true">
        <p class="text-gray-400 fs-4 fw-bold py-7">If you want to see the results, at least one employee must complete the evaluation!</p>
      </div>
    </div>

    <div class="card-px text-center pt-15 pb-15" v-else>
      <p class="text-gray-400 fs-4 fw-bold py-7">Evaluation is not completed!</p>
    </div>
  </div>
</template>

<script>
import QuestionTimelineDetailsComponent from "@/components/Questions/QuestionTimelineDetailsComponent.vue";
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
export default {
  name: "AnswersComparisonForGroupView",
  components: { QuestionTimelineDetailsComponent },

  data() {
    return {
      scoreColumnWidth: 320,
      filter: {
        SearchText: null,
        PageNumber: 1,
        OrderBy: "Name",
        Scope: null,
      },
      evaluated: {
        IsSigned: null,
        Categories: [],
        EvaluationScore: 0,
      },
      whoEvaluate: {
        IsSigned: null,
        Categories: [],
        EvaluationScore: 0,
      },
      answers: {},
      evaluatedEmployee: null,
      evaluatedBy: null,
      whoEvaluateMembers: [],
      evaluatedMembers: [],
      evaluationDetails: {},
    };
  },

  methods: {
    SelectEvaluateBy: function (selected) {
      this.$router
        .push(
          "/evaluation/" +
            this.$route.params.evalId +
            "/" +
            this.evaluatedEmployee.EmployeeId +
            "/" +
            selected.EmployeeId +
            "/answers/comparison-group"
        )
        .then(function () {
          window.location.reload();
        });
    },
    SelectMember: function (selected) {
      this.$router
        .push(
          "/evaluation/" +
            this.$route.params.evalId +
            "/" +
            selected.EmployeeId +
            "/" +
            this.evaluatedBy.EmployeeId +
            "/answers/comparison-group"
        )
        .then(function () {
          window.location.reload();
        });
    },
    PrepareAnswersList: function () {
      if (this.evaluated.IsSigned) {
        this.answers = this.whoEvaluate.Categories?.map((item) => {
          //assign evaluated questionGrade to whoEvaluate
          const newQuestions = item.Questions?.map((question) => {
            return {
              ...question,
              //if evaluated employee has completed the eval
              //we search his response in evaluated answers
              EvaluatedGrade: this.evaluated.Categories.find(
                (category) => category.CategoryId === item.CategoryId
              ).Questions.find((q) => q.QuestionId === question.QuestionId)
                .Grade,
            };
          });
          return {
            ...item,
            Questions: newQuestions,
            //assign evaluated categoryScore to whoEvaluate
            EvaluatedCategoryScore: this.evaluated.Categories.find(
              (category) => category.CategoryId === item.CategoryId
            ).CategoryScore,
          };
        });
      }
      if (this.whoEvaluate.IsSigned && !this.evaluated.IsSigned) {
        this.answers = this.whoEvaluate.Categories;
      }
    },
    GetEvaluatedAnswers: async function () {
      store.dispatch("changeLoaderStatus", true);
      var query = new URLSearchParams({
        employeeId: this.$route.params.employeeId,
        evaluationId: this.$route.params.evalId,
      });
      await this.$axios
        .get("api/Answers/getEvaluatedEmployeeAnswers?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.evaluated = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetWhoEvaluateAnswers: async function () {
      store.dispatch("changeLoaderStatus", true);
      var query = new URLSearchParams({
        employeeId: this.$route.params.employeeId,
        evaluatedBy: this.$route.params.evaluatedBy,
        evaluationId: this.$route.params.evalId,
      });
      await this.$axios
        .get("api/Answers/GetEmployeeWhoEvaluateAnswers?" + query, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.whoEvaluate = response.data;

          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },

    GetEvaluationMembers: async function () {
      store.dispatch("changeLoaderStatus", true);

      await this.$axios
        .get(
          "api/EvaluationPlan/getEvaluatedMembers?evaluationPlanId=" +
            this.$route.params.evalId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluatedMembers = response.data;
          this.evaluatedEmployee = response.data.find(
            (member) => member.EmployeeId == this.$route.params.employeeId
          );
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetMembersWhoEvaluate: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(
          "api/EvaluationPlan/getMembersWhoEvaluate?evaluationPlanId=" +
            this.$route.params.evalId +
            "&employeeId=" +
            this.$route.params.employeeId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.whoEvaluateMembers = response.data;
          this.evaluatedBy = response.data.find(
            (member) => member.EmployeeId == this.$route.params.evaluatedBy
          );
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetEvaluationDetails: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get(
          "api/EvaluationPlan/getEvaluationDetails/" +
            this.$route.params.evalId,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.evaluationDetails = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },

  async created() {
    await this.GetEvaluationDetails();
    this.GetMembersWhoEvaluate();
    this.GetEvaluationMembers();
    await this.GetWhoEvaluateAnswers();
    await this.GetEvaluatedAnswers();
    this.PrepareAnswersList();
  },
};
</script>
