<template>
  <div class="p-sm-3 p-lg-6 position-relativ-10z">
    <router-view></router-view>
  </div>
  <div>
    <img src="../../../src/assets/images/flori-bg-2.svg" class="left-fixed-img" />
    <img src="../../../src/assets/images/flori-Bg-1.svg" class="right-fixed-img" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentRoute: null,
    };
  },
  created() {
    this.currentRoute = this.$route.name;
  },
  watch: {
    $route(currentRoute, oldRoute) {
      //using router-view, if we change the page, created doesnt work
      if (currentRoute != oldRoute) {
        this.currentRoute = currentRoute.name;
      }
    },
  },
};
</script>
