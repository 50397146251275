<template>
  <div class="container set-items-on-mobile">
    <nav aria-label="breadcrumb" class="mb-8">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="#">Home</a></li>
        <li class="breadcrumb-item active"><span class="active" href="#">Compare</span></li>
      </ol>
    </nav>
    <h1 class="page-title">Compare</h1>
    <div class="card shadow">
      <div class="card-title">
        <div class="row page-tabs mb-0 pe-9 ps-9 pe-xxl-0 ps-xxl-0">
          <div class="col-sm-6 col-md-4 col-lg-3 col-xxl text-center" v-for="(item, index) in tabs" :key="index" :class="[{ 'p-xxl-0': index > 0 && index < tabs.length - 1, 'pe-xxl-0': index === 0, 'ps-xxl-0': index === tabs.length - 1 }, 'mb-3 mb-xxl-0']">
            <h3 v-bind:class="[tab === item ? 'active' : 'fw-normal']" class="pb-5 pointer" @click="ChangeTabs(item)">
              <img :src="require(`@/assets/images/scopes-icons/compare-view-icons/${item}.png`)" style="width: 32px; height: 32px" class="me-2" />
              {{ item }}
            </h3>
          </div>
        </div>
        <div class="card-body">
          <div v-if="tab === 'Company'" class="row">
            <div class="col-8 col-xl-6 col-xxl-4">
              <div class="row mb-32px">
                <div class="col">
                  <h1 class="main-category-title">Main categories</h1>
                </div>
                <div class="col-auto score-title">Score</div>
              </div>
              <div class="row category mb-4 pb-2" v-for="(category, index) in categoriesCompare.Categories" :key="index">
                <div class="col mb-5">
                  <div class="name">{{ category.Name }}</div>
                  <div class="description">{{ category.Description }}</div>
                </div>
                <div class="col-auto">
                  <div class="box-score">
                    {{ category.Avarage }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col"></div>
            <div class="col-auto">
              <div class="general-score-box">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="name">
                    General <br />
                    Score
                  </div>
                  <div class="number">{{ categoriesCompare.TotalAvarage }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="tab != 'Company'">
            <div v-if="viewOnlyGraphicOnResponsive != null" class="choose-view-section">
              <div class="row align-items-center">
                <div class="col-auto">
                  <h3 class="mb-0">Choose view:</h3>
                </div>
                <div class="col"></div>
                <div class="col-4 col-md-3">
                  <button type="button" class="btn btn w-100 text-center h-100" @click="viewOnlyGraphicOnResponsive = false" :class="[viewOnlyGraphicOnResponsive ? 'btn-secondary-alternative' : 'btn-gradient-success']">
                    <img :src="require(`@/assets/images/scopes-icons/compare-view-icons/${tab}.png`)" style="width: 25px; height: 25px" class="me-2" />
                    {{ tab }}
                  </button>
                </div>
                <div class="col-4 col-md-3">
                  <button type="button" class="btn w-100 text-center h-100" @click="viewOnlyGraphicOnResponsive = true" :class="[!viewOnlyGraphicOnResponsive ? 'btn-secondary-alternative' : 'btn-gradient-success']">
                    Graphic
                  </button>
                </div>
              </div>
            </div>

            <div class="row mt-5 pt-2">
              <!--chart content-->
              <div class="col-xxl-7" v-if="viewOnlyGraphicOnResponsive == null || viewOnlyGraphicOnResponsive === false">
                <div class="compare-config-description">
                  <div class="row align-items-center">
                    <div class="col">
                      Effortlessly compare and analyze employee evaluations, fostering a data-driven culture for organizational excellence. Streamline reviews, identify trends, and drive growth with
                      powerful insights.
                    </div>
                    <div class="col-auto">
                      <button type="button" class="btn btn-outline-green" @click="OpenComparationCriteraModal()">
                        <i class="fa-light fa-gear"></i>
                        Configuration
                      </button>
                    </div>
                  </div>
                </div>

                <div class="scroll-responsive pointer" id="chart-results-scroll">
                  <div class="row w-100">
                    <div class="col-3 first-sticky">
                      <div class="main-category-title">{{ tab }}</div>
                    </div>
                    <div class="col">
                      <div class="row" style="flex-wrap: nowrap">
                        <div class="col-auto chart" style="width: 170px" v-for="(category, index) in resultsCategories" :key="index">
                          <div class="employee-name" style="height: 34px; overflow: hidden" :title="category.Csategory.Name">{{ category.Csategory.Name }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5 pt-4 w-100">
                    <div class="col-3 first-sticky">
                      <div class="row category ms-1">
                        <!--
                        @click="ChartShowOnlyClickedEmployee(element)"
                      -->
                        <div class="col-12 border-bottom item-center-in-box ps-0" v-for="(element, index) in topcomparationFor" :key="index" style="min-height: 65px">
                          <div class="d-flex align-items-center">
                            <div class="user-colored-box me-3" :style="{ backgroundColor: element.Color }"></div>
                            <div class="name mb-0">
                              {{ element.Name }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto" style="width: 170px" v-for="(category, index) in resultsCategories" :key="index" :class="[index === resultsCategories.length - 1 ? 'ps-0' : 'p-0']">
                      <div v-for="(mat, indexResult) in category.Results" :key="indexResult" class="border-bottom item-center-in-box" style="min-height: 65px">
                        <div class="employee-score">
                          {{ mat[0]?.Avarage || "-" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--chart-->
              <div class="col-xxl-5 mt-5 mt-xxl-0 pt-5 pt-xxl-0" v-if="viewOnlyGraphicOnResponsive == null || viewOnlyGraphicOnResponsive === true">
                <Radar :data="chartData" :options="options" ref="radarChartRef" id="radarChart" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <comparation-criteria-modal :comparationForProps="comparationFor" :comparationForSelectedProps="comparationForSelected" :selectedCategoriesProps="selectedCategories" :categoriesTemplatesProps="categoriesTemplates" :searchProps="search" @getCompareBySelectedCriteriaEmit="GetCompareBySelectedCriteria" @getCategoriesTemplatesEmit="GetCategoriesTemplates" @getComparationForEmit="GetComparationFor"></comparation-criteria-modal>
</template>
<script>
import store from "@/utils/vuex.js";
import auth from "@/utils/auth.js";
import ComparationCriteriaModal from "@/components/Modals/Compare/ComparationCriteriaModal.vue";
import { Radar } from "vue-chartjs";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
export default {
  name: "CompareViewNew",
  components: {
    ComparationCriteriaModal,
    Radar,
  },
  data() {
    return {
      viewOnlyGraphicOnResponsive: false,
      tab: "Company",
      tabs: [],
      categoriesCompare: [],
      topcomparationFor: [],
      topCategoriesForCompare: [],
      employeesCompare: {},
      resultsCategories: [],
      comparationFor: [],
      comparationForSelected: [],
      categoriesTemplates: [],
      selectedCategories: [],
      teamsForCompare: [],
      teamsCompare: [],
      projectsCompare: [],
      search: {
        SearchCategory: null,
        SearchComparationFor: null,
      },
      data: {
        labels: [],
        datasets: [],
        backUp: [],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        // onClick: function (evt, item) {
        //   console.log("legend onClick", evt);
        //   console.log("legd item", item);
        // },
        responsive: true,
        maintainAspectRatio: false,
        //https://github.com/chartjs/Chart.js/issues/2779
        scales: {
          r: {
            angleLines: {
              display: false,
            },
            suggestedMin: 0,
            suggestedMax: 5,
            pointLabels: {
              font: {
                size: 12,
                color: "#cccccc",
              },
            },
          },
        },
        scale: {
          font: {
            size: 7,
          },
        },
      },
      chartColors: [
        {
          backgroundColor: "rgba(240, 15, 15, 0.2)",
          borderColor: "rgba(240, 15, 15, 1)",
          pointBackgroundColor: "rgba(240, 15, 15, 1)",
          pointHoverBorderColor: "rgba(240, 15, 15, 1)",
        },
        {
          backgroundColor: "rgba(240, 109, 15, 0.2)",
          borderColor: "rgba(240, 109, 15, 1)",
          pointBackgroundColor: "rgba(240, 109, 15, 1)",
          pointHoverBorderColor: "rgba(240, 109, 15, 1)",
        },
        {
          backgroundColor: "rgba(192, 192, 12, 0.2)",
          borderColor: "rgba(192, 192, 12, 1)",
          pointBackgroundColor: "rgba(192, 192, 12, 1)",
          pointHoverBorderColor: "rgba(192, 192, 12, 1)",
        },
        {
          backgroundColor: "rgba(147, 192, 12, 0.2)",
          borderColor: "rgba(147, 192, 12, 1)",
          pointBackgroundColor: "rgba(147, 192, 12, 1)",
          pointHoverBorderColor: "rgba(147, 192, 12, 1)",
        },
        {
          backgroundColor: "rgba(72, 192, 12, 0.2)",
          borderColor: "rgba(72, 192, 12, 1)",
          pointBackgroundColor: "rgba(72, 192, 12, 1)",
          pointHoverBorderColor: "rgba(72, 192, 12, 1)",
        },
        {
          backgroundColor: "rgba(12, 192, 27, 0.2)",
          borderColor: "rgba(12, 192, 27, 1)",
          pointBackgroundColor: "rgba(12, 192, 27, 1)",
          pointHoverBorderColor: "rgba(12, 192, 27, 1)",
        },
        {
          backgroundColor: "rgba(12, 192, 101, 0.2)",
          borderColor: "rgba(12, 192, 101, 1)",
          pointBackgroundColor: "rgba(12, 192, 101, 1)",
          pointHoverBorderColor: "rgba(12, 192, 101, 1)",
        },
        {
          backgroundColor: "rgba(12, 192, 177, 0.2)",
          borderColor: "rgba(12, 192, 177, 1)",
          pointBackgroundColor: "rgba(12, 192, 177, 1)",
          pointHoverBorderColor: "rgba(12, 192, 177, 1)",
        },
        {
          backgroundColor: "rgba(12, 132, 192, 0.2)",
          borderColor: "rgba(12, 132, 192, 1)",
          pointBackgroundColor: "rgba(12, 132, 192, 1)",
          pointHoverBorderColor: "rgba(12, 132, 192, 1)",
        },
        {
          backgroundColor: "rgba(15, 71, 240, 0.2)",
          borderColor: "rgba(15, 71, 240, 1)",
          pointBackgroundColor: "rgba(15, 71, 240, 1)",
          pointHoverBorderColor: "rgba(15, 71, 240, 1)",
        },
      ],
    };
  },
  computed: {
    chartData: function () {
      const newData = {
        labels: this.data.labels,
        datasets: this.data.datasets,
      };
      return newData;
    },
  },
  methods: {
    HandleWindowsResize() {
      this.viewOnlyGraphicOnResponsive = window.innerWidth <= 1200 || null;
      this.viewOnlyGraphicOnResponsive != null
        ? (this.options.plugins.legend.display = true)
        : (this.options.plugins.legend.display = false);
    },
    ChartShowOnlyClickedEmployee(employee) {
      // // Filter datasets based on employee id
      const filteredDatasets = [...this.data.backUp].filter(
        (set) => set.employeeId === employee.Id
      );
      this.data.datasets = filteredDatasets;
    },
    async OpenComparationCriteraModal() {
      //employees
      await this.GetComparationFor();
      this.comparationForSelected = this.topcomparationFor;
      //categories
      await this.GetCategoriesTemplates();
      this.selectedCategories = this.topCategoriesForCompare;
      $("#comparation-criteri-modal").modal("show");
    },
    FormatDataForChart: async function (result) {
      this.data = {};
      let randomIndex = 0;
      this.data = {
        labels: this.topCategoriesForCompare.map((x) => {
          return x.Name;
        }),
        datasets: this.topcomparationFor.map((x) => {
          var color = this.chartColors[randomIndex];
          randomIndex++;
          return {
            employeeId: x.Id,
            label: x.Name,
            borderWidth: 0,
            backgroundColor: color.backgroundColor,
            borderColor: color.borderColor,
            pointBackgroundColor: color.pointBackgroundColor,
            pointHoverBorderColor: color.pointHoverBorderColor,
            data: result
              .filter((y) => y.Id == x.Id)
              .map((y) => {
                return y.Avarage;
              }),
          };
        }),
      };
      this.data.backUp = this.data.datasets;
      this.topcomparationFor = this.topcomparationFor.map((item, index) => ({
        ...item,
        Color: this.data.datasets[index].borderColor,
      }));
      this.$utils.DragScrollByElementId("chart-results-scroll");
    },
    CreateTabsList: function () {
      if (this.$store.getUser?.UserRoles?.includes("Admin")) {
        this.tabs = [
          "Company",
          "Employees",
          "Teams",
          "Projects",
          "Groups",
          "Team Leaders",
          "Project Managers",
          "Group Leaders",
        ];
        return;
      } else {
        this.tabs = ["Company", "Employees"];
        if (this.$store.getUser?.UserRoles?.includes("TeamLeader")) {
          this.tabs.push("Teams");
        }
        if (this.$store.getUser?.UserRoles?.includes("ProjectManager")) {
          this.tabs.push("Projects");
        }
        if (this.$store.getUser?.UserRoles?.includes("GroupLeader")) {
          this.tabs.push("Groups");
        }
      }
      return;
    },
    async GetComparationFor() {
      switch (this.tab) {
        case "Company":
          break;
        case "Employees":
          await this.GetAllEmployeesDetails();
          break;
        case "Teams":
          await this.GetAllTeamsForCompare();
          break;
        case "Projects":
          await this.GetAllProjectsForCompare();
          break;
        case "Groups":
          await this.GetAllGroupsForCompare();
          break;
        case "Team Leaders":
          await this.GetAllTLForCompare();
          break;
        case "Project Managers":
          await this.GetAllPMForCompare();
          break;
        case "Group Leaders":
          await this.GetAllGLForCompare();
          break;
      }
    },
    ChangeTabs: async function (item) {
      this.search = {
        SearchCategory: null,
        SearchComparationFor: null,
      };
      this.tab = item;
      this.comparationForSelected = [];
      await this.GetTopCategoriesForCompare();
      var categoriesId = this.topCategoriesForCompare.map(
        (category) => category.Id
      );
      let createdBy = [];
      switch (item) {
        case "Company":
          this.GetCategoriesCompare();
          break;
        case "Employees":
          await this.GetEmployeesForCompare();
          createdBy = this.topcomparationFor.map((employee) => employee.Id);
          await this.GetEmployeesCompare(createdBy, categoriesId);
          this.CreateMatrice(
            this.topCategoriesForCompare,
            this.topcomparationFor,
            this.employeesCompare
          );
          await this.FormatDataForChart(this.employeesCompare);
          break;
        case "Teams":
          await this.GetTeamsForCompare();
          createdBy = this.topcomparationFor.map((employee) => employee.Id);
          await this.GetTeamsCompare(createdBy, categoriesId);
          this.CreateMatrice(
            this.topCategoriesForCompare,
            this.topcomparationFor,
            this.teamsCompare
          );
          await this.FormatDataForChart(this.teamsCompare);

          break;
        case "Projects":
          await this.GetProjectsForCompare();
          createdBy = this.topcomparationFor.map((employee) => employee.Id);
          await this.GetProjectsCompare(createdBy, categoriesId);
          this.CreateMatrice(
            this.topCategoriesForCompare,
            this.topcomparationFor,
            this.projectsCompare
          );
          await this.FormatDataForChart(this.projectsCompare);

          break;
        case "Groups":
          await this.GetGroupsForCompare();
          createdBy = this.topcomparationFor.map((employee) => employee.Id);
          await this.GetGroupsCompare(createdBy, categoriesId);
          this.CreateMatrice(
            this.topCategoriesForCompare,
            this.topcomparationFor,
            this.projectsCompare
          );
          await this.FormatDataForChart(this.projectsCompare);

          break;
        case "Team Leaders":
          await this.GetTopTLForCompare();
          createdBy = this.topcomparationFor.map((employee) => employee.Id);
          await this.GetEmployeesCompare(createdBy, categoriesId);
          this.CreateMatrice(
            this.topCategoriesForCompare,
            this.topcomparationFor,
            this.employeesCompare
          );
          await this.FormatDataForChart(this.employeesCompare);

          break;
        case "Project Managers":
          await this.GetTopPMForCompare();
          createdBy = this.topcomparationFor.map((employee) => employee.Id);
          await this.GetEmployeesCompare(createdBy, categoriesId);
          this.CreateMatrice(
            this.topCategoriesForCompare,
            this.topcomparationFor,
            this.employeesCompare
          );
          await this.FormatDataForChart(this.employeesCompare);
          break;
        case "Group Leaders":
          await this.GetTopGLForCompare();
          createdBy = this.topcomparationFor.map((employee) => employee.Id);
          await this.GetEmployeesCompare(createdBy, categoriesId);
          this.CreateMatrice(
            this.topCategoriesForCompare,
            this.topcomparationFor,
            this.employeesCompare
          );
          await this.FormatDataForChart(this.employeesCompare);

          break;
      }
    },
    GetCompareBySelectedCriteria: async function (props) {
      this.comparationForSelected = props.comparation;
      this.selectedCategories = props.categories;
      this.topcomparationFor = this.comparationForSelected;
      this.topCategoriesForCompare = this.selectedCategories;
      var createdBy = this.comparationForSelected.map(
        (employee) => employee.Id
      );
      var categoriesId = this.selectedCategories.map((category) => category.Id);
      $("#comparation-criteri-modal").modal("hide");

      switch (this.tab) {
        case "Company":
          break;
        case "Employees":
        case "Team Leaders":
        case "Group Leaders":
        case "Project Managers":
          await this.GetEmployeesCompare(createdBy, categoriesId);
          this.CreateMatrice(
            this.selectedCategories,
            this.comparationForSelected,
            this.employeesCompare
          );
          await this.FormatDataForChart(this.employeesCompare);
          break;
        case "Teams":
          await this.GetTeamsCompare(createdBy, categoriesId);
          this.CreateMatrice(
            this.selectedCategories,
            this.comparationForSelected,
            this.teamsCompare
          );
          await this.FormatDataForChart(this.teamsCompare);
          break;
        case "Projects":
          await this.GetProjectsCompare(createdBy, categoriesId);
          this.CreateMatrice(
            this.selectedCategories,
            this.comparationForSelected,
            this.projectsCompare
          );
          await this.FormatDataForChart(this.projectsCompare);
          break;
        case "Groups":
          await this.GetGroupsCompare(createdBy, categoriesId);
          this.CreateMatrice(
            this.selectedCategories,
            this.comparationForSelected,
            this.projectsCompare
          );
          await this.FormatDataForChart(this.projectsCompare);
          break;
      }
    },
    GetCategoriesTemplates: async function () {
      if (!this.search.SearchCategory) {
        this.search.SearchCategory = "";
      }
      await this.$axios
        .get(
          "api/Compare/getAllCategories?searchText=" +
            this.search.SearchCategory,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.categoriesTemplates = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },
    GetAllEmployeesDetails: async function () {
      if (!this.search.SearchComparationFor) {
        this.search.SearchComparationFor = "";
      }
      await this.$axios
        .get(
          "api/Compare/getAllEmployeesDetails?searchText=" +
            this.search.SearchComparationFor,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.comparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },
    GetAllTeamsForCompare: async function () {
      if (!this.search.SearchComparationFor) {
        this.search.SearchComparationFor = "";
      }
      await this.$axios
        .get(
          "api/Compare/getAllTeamsForCompare?searchText=" +
            this.search.SearchComparationFor,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.comparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },
    GetAllGLForCompare: async function () {
      if (!this.search.SearchComparationFor) {
        this.search.SearchComparationFor = "";
      }
      await this.$axios
        .get(
          "api/Compare/getAllGLForCompare?searchText=" +
            this.search.SearchComparationFor,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.comparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },
    GetAllProjectsForCompare: async function (searchText) {
      if (!this.search.SearchComparationFor) {
        this.search.SearchComparationFor = "";
      }
      await this.$axios
        .get(
          "api/Compare/getAllProjectsForCompare?searchText=" +
            this.search.SearchComparationFor,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.comparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },
    GetAllGroupsForCompare: async function (searchText) {
      if (!this.search.SearchComparationFor) {
        this.search.SearchComparationFor = "";
      }
      await this.$axios
        .get(
          "api/Compare/getAllGroupsForCompare?searchText=" +
            this.search.SearchComparationFor,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.comparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },
    GetAllTLForCompare: async function (searchText) {
      if (!this.search.SearchComparationFor) {
        this.search.SearchComparationFor = "";
      }
      await this.$axios
        .get(
          "api/Compare/getAllTLForCompare?searchText=" +
            this.search.SearchComparationFor,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.comparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },
    GetAllPMForCompare: async function (searchText) {
      if (!this.search.SearchComparationFor) {
        this.search.SearchComparationFor = "";
      }
      await this.$axios
        .get(
          "api/Compare/getAllPMForCompare?searchText=" +
            this.search.SearchComparationFor,
          {
            headers: {
              Authorization: "Bearer " + auth.getJwtToken().Value,
            },
          }
        )
        .then((response) => {
          this.comparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
        });
    },
    CreateMatrice: function (categories, topcomparationFor, results) {
      this.resultsCategories = [];
      categories.map((category) => {
        var matrice = {
          Csategory: category,
          Results: [],
        };
        topcomparationFor.map((item) => {
          var result = results.filter(
            (res) => res.CategoryId == category.Id && res.Id == item.Id
          );
          matrice.Results.push(result);
        });
        this.resultsCategories.push(matrice);
      });
    },
    GetCategoriesCompare: function () {
      store.dispatch("changeLoaderStatus", true);
      this.$axios
        .get("api/Compare/getCategoryCompare?", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.categoriesCompare = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetEmployeesForCompare: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Compare/getEmployeesForCompare?", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.topcomparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetTeamsForCompare: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Compare/getTopTeamsForCompare?", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.topcomparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetGroupsForCompare: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Compare/getTopGroupsForCompare?", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.topcomparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetProjectsForCompare: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Compare/getTopProjectsForCompare?", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.topcomparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetTopCategoriesForCompare: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Compare/getTopCategoriesForCompare?", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.topCategoriesForCompare = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetTopTLForCompare: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Compare/getTopTLForCompare?", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.topcomparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetTopGLForCompare: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Compare/getTopGLForCompare?", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.topcomparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetTopPMForCompare: async function () {
      store.dispatch("changeLoaderStatus", true);
      await this.$axios
        .get("api/Compare/getTopPMForCompare?", {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.topcomparationFor = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetEmployeesCompare: async function (createdBy, categoriesId) {
      store.dispatch("changeLoaderStatus", true);
      var compareFilter = {
        CompareBy: createdBy,
        CategoriesId: categoriesId,
      };
      await this.$axios
        .post("api/Compare/getEmployeesComapare", compareFilter, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.employeesCompare = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetTeamsCompare: async function (createdBy, categoriesId) {
      store.dispatch("changeLoaderStatus", true);
      var compareFilter = {
        CompareBy: createdBy,
        CategoriesId: categoriesId,
      };
      await this.$axios
        .post("api/Compare/getTeamsCompare", compareFilter, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.teamsCompare = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetProjectsCompare: async function (createdBy, categoriesId) {
      store.dispatch("changeLoaderStatus", true);
      var compareFilter = {
        CompareBy: createdBy,
        CategoriesId: categoriesId,
      };
      await this.$axios
        .post("api/Compare/getProjectsCompare", compareFilter, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.projectsCompare = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
    GetGroupsCompare: async function (createdBy, categoriesId) {
      store.dispatch("changeLoaderStatus", true);
      var compareFilter = {
        CompareBy: createdBy,
        CategoriesId: categoriesId,
      };
      await this.$axios
        .post("api/Compare/getGroupsCompare", compareFilter, {
          headers: {
            Authorization: "Bearer " + auth.getJwtToken().Value,
          },
        })
        .then((response) => {
          this.projectsCompare = response.data;
          store.dispatch("changeLoaderStatus", false);
        })
        .catch((error) => {
          store.dispatch("toastNotify", {
            Type: "error",
            Title: error.response.data.message,
          });
          store.dispatch("changeLoaderStatus", false);
        });
    },
  },
  mounted() {
    this.HandleWindowsResize();
    window.addEventListener("resize", this.HandleWindowsResize, {
      passive: true,
    });
  },
  created() {
    this.CreateTabsList();
    this.GetCategoriesCompare();
  },
};
</script>
<style scoped>
.compare-config-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  color: #6c757d;
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.scroll-responsive {
  overflow: auto;
  max-width: 1300px;
  position: relative;
}

.scroll-responsive .first-sticky {
  position: sticky;
  left: 0;
  background-color: white;
}

.scroll-responsive > .row {
  flex-wrap: nowrap;
}

.choose-view-section {
  background-color: #eff2f5;
  border-radius: 0.475rem;
  padding: 12px;
}

.set-items-on-mobile {
  max-width: 100vw;
}
.set-items-on-mobile .page-tabs h3 {
  font-size: 14px;
}

@media (max-width: 1400px) {
  .page-tabs h3 {
    border: 1px solid #dee2e6;
    border-radius: 0.475rem;
    padding: 6px !important;
  }
  .page-tabs .active {
    border: 1px solid #188a94 !important;
    padding: 6px !important;
    border-radius: 0.475rem;
  }
}
@media (min-width: 1400px) {
  .set-items-on-mobile .page-tabs h3 {
    font-size: 13px;
    border-bottom: 1px solid #dee2e6;
  }
}
@media (min-width: 1550px) {
  .set-items-on-mobile .page-tabs h3 {
    font-size: 14px;
  }
}
@media (min-width: 1550px) {
  .set-items-on-mobile .page-tabs h3 {
    font-size: 14px;
  }
  .set-items-on-mobile {
    max-width: 90vw;
  }
}
@media (min-width: 1700px) {
  .set-items-on-mobile .page-tabs h3 {
    font-size: 16px;
  }
}

.page-tabs {
  margin-top: 12px;
}
.page-tabs h3 {
  color: #6c757d;
  font-size: 16px;
  border-bottom: 1px solid #dee2e6;
}
.chart .employee-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #6c757d;
  text-transform: uppercase;
  text-align: center;
}

.category .name {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #495057;
  margin-bottom: 8px;
}
.category .description {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #6c757d;
}

.category .box-score {
  width: 44px;
  height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: white;
  background-color: #4dc4b3;
}

.main-category-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  color: #343a40;
}

.score-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  color: #6c757d;
  text-transform: uppercase;
}

.general-score-box {
  background: linear-gradient(87.6deg, #4dc4b3 0%, #209b8a 100%);
  height: 60px;
  width: 195px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 12px 32px;
  border-radius: 4px;
}

.general-score-box .name {
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  color: white;
  text-transform: uppercase;
}
.general-score-box .number {
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
}

.employee-score {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #188a94;
}

.item-center-in-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.user-colored-box {
  width: 16px;
  height: 16px;
  border-radius: 4px;
}

.user-border-bottom {
  height: 2px;
  width: 69%;
  border-radius: 8px;
  margin-top: 10px;
}
.btn-gradient-success {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-secondary-alternative {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
